
export function isValidEmailDomain(email, domains, team) {
    if (!email) return true;
    if(email === team.default_from_email) return true;
    // Extract the domain part of the email
    const emailDomain = email.split('@')[1];

    // Check if the domain exists in the list of valid domains
    const isValid = domains.some(domain => domain.domain === emailDomain);
    return isValid;
}



export const triggerOptions =[
    {
        value: 'campaign_sent',
        label: 'a campaign is sent to a contact',
        condition_label: null
    },  {
        value: 'campaign_viewed',
        label: 'a campaign is viewed',
        condition_label: 'has the campaign been viewed?'
    }, {
        value: 'campaign_clicked',
        label: 'a campaign is clicked',
        condition_label: 'has the campaign been clicked?'
    }, {
        value: 'promo_viewed',
        label: 'a promo is viewed',
        condition_label: 'has the promo been viewed?'
    },
    {
        value: 'promo_listened',
        label: 'any track on a promo is played',
        condition_label: 'has the contact played any tracks on the promo?'
    },
    {
        value: 'track_played',
        label: 'a track is played',
        condition_label: 'has a track from the promo been played?'
    },
    {
        value: 'feedback_submitted',
        label: 'feedback is submitted',
        condition_label: 'has the contact submitted feedback?'
    },
    {
        value: 'promo_contact_added',
        label: 'contact has been given access to a promo',
        condition_label: null
    }
];
